import React, { useEffect, useState } from "react";
import Seo from "../common/seo.js";
import Layout from "../common/layout.js";
import { Link } from "gatsby";
import { fullDate } from '../../utils/date_format.js';

const ResourceCenter = () => {
  const [resources, setResources] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allTags, setAllTags] = useState([]);
  const resourcesPerPage = 12;

  useEffect(() => {
    fetch('/.netlify/functions/resources')
      .then(res => res.json())
      .then(data => {
        const sortedResources = data.resources.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA; // Sort in descending order (most recent first)
        });
        setResources(sortedResources);
        extractTags(sortedResources);
      })
      .catch(error => {
        console.error('Error fetching resources:', error);
      });
  }, []);  

  const extractTags = (resources) => {
    const tagsSet = new Set();
    resources.forEach(resource => {
      const tags = resource.newTags['fr'] || resource.newTags['en-CA'];
      tags?.forEach(tag => tagsSet.add(tag));
    });
    setAllTags([...tagsSet]);
  };

  const filteredResources = resources.filter(resource => {
    // Exclude items with no French title
    if (!resource.title?.['fr']) {
      return false;
    }
  
    const searchWords = search.toLowerCase().split(" ");
    
    const dateMatchesSearch = (date) => {
      const formattedDate = fullDate(date);
      return searchWords.every(word => formattedDate.toLowerCase().includes(word));
    };
    
    const titleField = resource.title?.['fr'] || resource.title?.['en-CA'] || "";
    const excerptField = resource.excerpt?.['fr'] || resource.excerpt?.['en-CA'] || "";
    const contentField = resource.content?.['fr'] || resource.content?.['en-CA'] || "";
    const tagsField = resource.newTags?.['fr'] || resource.newTags?.['en-CA'] || [];
    const dateField = resource.date?.['en-CA'] || "";
    
    // Ensure the fields are strings before calling .toLowerCase()
    const titleMatch = !search || searchWords.every(word => (typeof titleField === "string" && titleField.toLowerCase().includes(word)));
    const excerptMatch = !search || searchWords.every(word => (typeof excerptField === "string" && excerptField.toLowerCase().includes(word)));
    const contentMatch = !search || searchWords.every(word => (typeof contentField === "string" && contentField.toLowerCase().includes(word)));
    const tagMatch = !search || searchWords.every(word => tagsField?.some(tag => tag.toLowerCase().includes(word)));
    const dateMatch = !search || dateMatchesSearch(dateField);
    
    return (
      (titleMatch || excerptMatch || contentMatch || tagMatch || dateMatch) &&
      (selectedTags.length === 0 || tagsField?.some(tag => selectedTags.includes(tag)))
    );
  });
  
  

  const handleClick = (e, tag) => {
    e.preventDefault();
    setSelectedTags((prevTags) => prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]);
    setCurrentPage(1);
  };

  const handleViewAllClick = (e) => {
    e.preventDefault();
    setSelectedTags([]);
    setCurrentPage(1);
  };

  const indexOfLastResource = currentPage * resourcesPerPage;
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
  const currentResources = filteredResources.slice(indexOfFirstResource, indexOfLastResource);

  const pageNumbers = Array.from({ length: Math.ceil(filteredResources.length / resourcesPerPage) }, (_, i) => i + 1);

  return (
    <div className="resourcesPage">
        <div className="resourcesHero">
          <div className="container">
            <h1>
              <span>COMMB</span> Ressources de vente
            </h1>
            <p>
            Ce centre de ressources vise à soutenir l'industrie de la publicité extérieure (OOH) en fournissant des informations pertinentes et des preuves de la valeur de l'OOH.
            </p>
            <div className="resourceSearchWrapper">
              <input placeholder="Rechercher" value={search} onChange={e => setSearch(e.target.value)} />
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 16.5L21 21.5" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
                <path d="M1 10.0714C1 14.8053 4.83756 18.6429 9.57143 18.6429C11.9424 18.6429 14.0887 17.6801 15.6404 16.1243C17.1869 14.5739 18.1429 12.4343 18.1429 10.0714C18.1429 5.33756 14.3053 1.5 9.57143 1.5C4.83756 1.5 1 5.33756 1 10.0714Z" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
              </svg>
            </div>
            <div className="resourceHeroTagBtnWrapper">
              {allTags.map(tag => (
                <a key={tag} href="#" onClick={(e) => handleClick(e, tag)} className={selectedTags.includes(tag) ? 'active' : ''}>{tag}</a>
              ))}
              <a href="#" className={`viewAllResourcesBtn ${selectedTags.length === 0 ? 'active' : ''}`} onClick={handleViewAllClick}>
                Voir tout
              </a>
            </div>
          </div>
        </div>
        <div className="resourceCardWrapper">
          <div className="container">
            {currentResources.length > 0 ? currentResources.map(resource => (
              <div
                className={`resourceGridCard ${!resource.title['fr'] ? 'no-fr-title' : ''}`}
                key={resource.objectID}
              >
                <Link to={`/fr/resource-centre/${resource.slug['en-CA']}`}>
                  <img src={resource.imageFr} alt={resource.title['en-CA']} />
                </Link>
                <div className="resourceCardDetails">
                  <div className="pillsCategoryWrapper">
                    {resource.newTags['fr'].map((tag, index) => (
                      <span key={index} className="pillsCategory">{tag}</span>
                    ))}
                  </div>
                  <h2>
                    <Link to={`/fr/resource-centre/${resource.slug['en-CA']}`}>
                      {resource.title['fr']}
                    </Link>
                  </h2>
                  <p>
                    {resource.excerpt['fr']}
                  </p>
                  <hr />
                  <div className="resourceCardButtonWrapper">
                    <Link to={`/fr/resource-centre/${resource.slug['en-CA']}`} className="resourceMoreDetailsBtn">
                      Plus de détails
                    </Link>
                    <a href={resource.fileUrl['fr']} className="resourceDownloadBtn" target="_blank" rel="noopener noreferrer">
                      Télécharger
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8667 9.74609V12.8658H1.13333V9.74609H0V14.0002H12V9.74609H10.8667Z" fill="#BEBEBE" />
                        <path d="M2.94424 7.03327L6.04175 10.6946L9.07214 7.03328L6.62098 7.03328L6.62098 -1.06623e-07L5.3954 -1.59935e-07L5.3954 7.03327L2.94424 7.03327Z" fill="#BEBEBE" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            )) : <p>Aucune ressource trouvée</p>}
          </div>
          <div className="resourcePagination">
            <span
              className={`paginationLeftArrow ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            >
              &#9664;
            </span>
            {pageNumbers.map(number => (
              <span
                key={number}
                className={`paginationBtn ${number === currentPage ? 'active' : ''}`}
                onClick={() => setCurrentPage(number)}
              >
                {number}
              </span>
            ))}
            <span
              className={`paginationRightArrow ${currentPage === pageNumbers.length || currentResources.length < resourcesPerPage ? 'disabled' : ''}`}
              onClick={() => currentPage < pageNumbers.length && setCurrentPage(currentPage + 1)}
            >
              &#9654;
            </span>
          </div>
        </div>
    </div>
  );
};

export default ResourceCenter;
